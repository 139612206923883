import Skeleton from "react-loading-skeleton";
import { useMutation, useQuery,  useQueryClient } from "react-query";
import Modal from "react-modal";
import { useContext, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { errorHandler } from "utils/errorHandler";
import { Title } from "utils/Title";
import PageMainHeader from "components/PageMainHeader";
import ModalConfigVars from "components/modals/ModalConfigVars";
import IconButton from "@leafygreen-ui/icon-button";
import Banner from "@leafygreen-ui/banner";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "contexts/AuthContext"
import socketIOClinet from "socket.io-client";
import _ from "lodash";

const socket = socketIOClinet(process.env.REACT_APP_SOCKET_ENDPOINT);

Modal.setAppElement("#root");

function fetchConfigVars() {
  console.log("* fetchConfigVars init")
  return new Promise(async (resolve, reject) => {
    try {
      let response;
      if(window?.printflo_api) {
        response = await window.printflo_api._fetchConfigVars();
        if(_.isError(response)) throw response;
      } else {
        if(socket?.connected){
          const promise = () => {
            return new Promise((resolve) => {
              socket.emit("on-config-vars:fetch", (callback) => {
                console.log("[fetchConfigVars:on-config-vars:fetch] callback: ", callback)
                resolve(callback)
              })
            })
          }
          response = await promise()
        }
      }
      console.log("[fetchConfigVars] response: ", response)
      resolve(response);
    } catch (error) {
      console.log("[fetchConfigVars] error: ", error);
      reject(errorHandler(error));
    }
  })
}

function updateConfigVar(config) {
  console.log("* updateConfigVar init")
  return new Promise(async (resolve, reject) => {
    let response;
    try {
      if(window?.printflo_api) {
        response = await window.printflo_api._updateConfigVars(config);
        if(_.isError(response)) throw response;
      } else {
        if(socket?.connected){
          socket.emit("on-config-vars:update", config, (callback) => {
            console.log("[fetchConfigVars:on-config-vars:update] callback: ", callback)
            response = callback
          })
        }
      }
      console.log("[updateConfigVar] response: ", response)
      resolve(response);
    } catch (error) {
      console.log("updateConfigVar error", error);
      reject(error);
    }

  })
}

const title = "Config Vars";
const description = "Config vars change the way your app behaves.";

export default function ConfigVars() {
  // console.log("** ConfigVars fn init")
  const { isLoading, isError, error, data } = useQuery("configVars", fetchConfigVars);
  const queryClient = useQueryClient();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [config, setConfig] = useState("");
  const { logout } = useContext(AuthContext);

  const mutation = useMutation(async config => {
    console.log('* mutation init');
    console.log('[mutation] config: ', config)
    return await updateConfigVar(config);
  }, {
    onMutate: variables => {
      console.log("> onMutate init");
      queryClient.cancelQueries("configVars");
      
      const previousConfigvars = queryClient.getQueryData("configVars");
      
      queryClient.setQueryData("configVars", old => {
        let { key, value } = variables;
        console.log("old configVars", old);
        return { ...old, [key]:value }
      });

      return { previousConfigvars }
    },
    onSuccess: async (data) => {
      console.log("[mutation:onSuccess] data: ", data);
      const confirm = window.confirm("Restart required to take effect for the updated config")
      if(confirm) {
        const result = await logout()
        if(result) {
          if(window?.printflo_api) {
            window.printflo_api._appRelaunch()
          } else {
            socket.emit("on-app-relaunch")
          }
        }
      }
    },
    onError: (error, variables, context) => {
      console.log("[mutation:onSuccess] error: ", error);
      queryClient.setQueryData(context.previousConfigvars);
    },
    onSettled: () => {
      queryClient.invalidateQueries("configVars");
    }
  });

  function editConfig({key, value}) {
    console.log("* editConfig init");
    console.log("[editConfig] key:value", key, value);
    setModalIsOpen(true);
    setConfig(() => ({ key, value }));
  }

  function handleSubmit() {
    console.log("* handleSubmit init")
    console.log("[handleSubmit] config: ", config);
    mutation.mutate(config);
    setModalIsOpen(false);
  }

  return (
    <>
      <Title title={title} />
      <ToastContainer theme="dark" />
      <PageMainHeader 
        title={title} 
        description={description} 
      />
      <section className="primary">
        {
          isLoading
            ? <Skeleton count={10} height={50} circle={true}/>
            : isError
              ? (
                <Banner variant="danger" className="mb-10">
                  {error?.message ? error.message : error}
                </Banner>
              )
              : (
                <>
                <table>
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th style={{maxWidth: 300}}>Value</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                      {/* {configVars && Object.keys(configVars).map((key, i) => { */}
                      {data && Object.keys(data).map((key, i) => {
                        return (
                          <tr key={i}>
                            <td>{key}</td>
                            <td style={{maxWidth: 300, overflowWrap: "break-word"}}>{data[key]}</td>
                            <td style={{ textAlign: "center" }}>
                              <IconButton
                                onClick={() => editConfig({ key, value: data[key] })}
                                className="swing-icon"
                                aria-label="Queue print"
                              >
                                <FaEdit />
                              </IconButton>
                            </td>
                          </tr>
                        )
                    })

                    }
                  </tbody>
                </table>
                </>
              )
        }
      </section>
      <ModalConfigVars modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} title={title} mode="Edit" config={config} setConfig={setConfig} submitHandler={handleSubmit} />
    </>
  )
}
