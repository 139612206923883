import React from "react";
import { useIntl } from "react-intl";
import {
  ProSidebar,
  Menu,
  SubMenu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FaArrowLeft,
  FaArrowRight,
  FaPrint,
  FaIndustry,
  FaTachometerAlt,
  FaImages,
  FaProductHunt,
  FaBox,
  FaBoxes,
  FaUsersCog,
  FaClipboardList,
  FaShippingFast,
  FaObjectGroup,
  FaTruckLoading,
  FaHdd,
  FaBoxOpen,
  FaCogs,
  FaRecycle,
  FaLayerGroup,
  FaGem,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import Tooltip from "@leafygreen-ui/tooltip";
import { AuthContext } from "contexts/AuthContext";
import { SettingsContext } from "contexts/SettingsContext";

const Aside = React.memo(
  ({ collapsed, toggled, handleToggleSidebar, handleCollapsedChange }) => {
    const { user } = React.useContext(AuthContext);
    const { settings } = React.useContext(SettingsContext);
    const { pathname } = useLocation();
    // console.log("pathname: ", pathname);
    // console.log("collapsed: ", collapsed);
    // console.log("- user: ", user);
    // console.log("- settings: ", settings);
    const intl = useIntl();
    return (
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
        className="hidden-print"
      >
        <SidebarHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: "20px",
              letterSpacing: "2px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              height: "50px",
            }}
          >
            {!collapsed ? (
              <span style={{ display: "inline-block" }}>
                {intl.formatMessage({ id: "sidebarTitle" })}
              </span>
            ) : (
              <img
                src={window?.printflo_api ? `32x32.png` : "/32x32.png"}
                alt="logo"
              />
            )}
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            <Tooltip
              align="right"
              className="lg-tooltip-container"
              popoverZIndex={99999}
              enabled={collapsed}
              darkMode={true}
              trigger={
                <MenuItem
                  icon={<FaTachometerAlt />}
                  active={pathname.includes("dashboard")}
                >
                  {intl.formatMessage({ id: "dashboard" })}
                  <Link to="/" />
                </MenuItem>
              }
            >
              {intl.formatMessage({ id: "dashboard" })}
            </Tooltip>
            <SubMenu
              title={intl.formatMessage({ id: "orders" })}
              icon={<FaIndustry />}
            >
              <MenuItem
                icon={<FaIndustry />}
                active={pathname.includes("workorders")}
              >
                {intl.formatMessage({ id: "workOrders" })}
                <Link to="/work-orders" />
              </MenuItem>
              {settings?.defaultOrderManagement !== "manual" && (
                <MenuItem
                  icon={<FaShippingFast />}
                  active={pathname.includes("shipped-orders")}
                >
                  {intl.formatMessage({ id: "shippedOrders" })}
                  <Link to="/shipped-orders" />
                </MenuItem>
              )}

              <MenuItem
                icon={<FaBoxOpen />}
                active={pathname.includes("packing-list")}
              >
                {intl.formatMessage({ id: "packingList" })}
                <Link to="/packing-list" />
              </MenuItem>
              <MenuItem
                icon={<FaShippingFast />}
                active={pathname.includes("browsing-order-item")}
              >
                {intl.formatMessage({ id: "browsingOrderItem" })}
                <Link to="/browsing-order-item" />
              </MenuItem>
              <MenuItem
                icon={<FaShippingFast />}
                active={pathname.includes("order-items-activity")}
              >
                {intl.formatMessage({ id: "orderItemsActivity" })}
                <Link to="/order-items-activity" />
              </MenuItem>
              <MenuItem
                icon={<FaObjectGroup />}
                active={pathname.includes("consolidation")}
              >
                {intl.formatMessage({ id: "consolidation" })}
                <Link to="/consolidation" />
              </MenuItem>

              {user?.role === "admin" &&
                settings?.defaultOrderManagement !== "manual" && (
                  <MenuItem
                    icon={<FaShippingFast />}
                    active={pathname.includes("sync-orders-backend")}
                  >
                    {intl.formatMessage({ id: "syncOrders" })}
                    <Link to="/sync-orders-backend" />
                  </MenuItem>
                )}
            </SubMenu>

            <Tooltip
              align="right"
              className="lg-tooltip-container"
              popoverZIndex={99999}
              enabled={collapsed}
              darkMode={true}
              trigger={
                <MenuItem
                  icon={<FaProductHunt />}
                  active={pathname.includes("picking-list")}
                >
                  {intl.formatMessage({ id: "pickingList" })}
                  <Link to="/picking-list" />
                </MenuItem>
              }
            >
              {intl.formatMessage({ id: "pickingList" })}
            </Tooltip>
            <Tooltip
              align="right"
              className="lg-tooltip-container"
              popoverZIndex={99999}
              enabled={collapsed}
              darkMode={true}
              trigger={
                <MenuItem
                  icon={<FaPrint />}
                  active={pathname.includes("queuePrint")}
                >
                  {intl.formatMessage({ id: "queuePrint" })}
                  <Link to="/queue-print" />
                </MenuItem>
              }
            >
              {intl.formatMessage({ id: "queuePrint" })}
            </Tooltip>
            {/* {_.includes(settings?.defaultIntegratedAutomation, 'GTX') 
            ? (
              <Tooltip
                align='right'
                className='lg-tooltip-container'
                popoverZIndex={99999}
                enabled={collapsed}
                darkMode={true}
                trigger={   
                  <MenuItem
                    icon={<FaGoodreads />}
                    active={pathname.includes("brother")}
                  >
                    {intl.formatMessage({ id: "brother" })}
                    <Link to="/queue-print-brother" />
                  </MenuItem>
                }
              >
                {intl.formatMessage({ id: "brother" })}
              </Tooltip>
            ) 
            : (
              <Tooltip
                align='right'
                className='lg-tooltip-container'
                popoverZIndex={99999}
                enabled={collapsed}
                darkMode={true}
                trigger={   
                  <MenuItem
                    icon={<FaPrint />}
                    active={pathname.includes("epson")}
                  >
                    {intl.formatMessage({ id: "epson" })}
                    <Link to="/queue-print-epson" />
                  </MenuItem>
                }
              >
                {intl.formatMessage({ id: "epson" })}
              </Tooltip>
            )} */}

            <Tooltip
              align="right"
              className="lg-tooltip-container"
              popoverZIndex={99999}
              enabled={collapsed}
              darkMode={true}
              trigger={
                <MenuItem
                  icon={<FaTruckLoading />}
                  active={pathname.includes("shipment")}
                >
                  {intl.formatMessage({ id: "shipment" })}
                  <Link to="/shipment" />
                </MenuItem>
              }
            >
              {intl.formatMessage({ id: "shipment" })}
            </Tooltip>
            <SubMenu
              title={intl.formatMessage({ id: "items" })}
              icon={<FaBox />}
            >
              <MenuItem
                icon={<FaProductHunt />}
                active={pathname.includes("productions")}
              >
                {intl.formatMessage({ id: "productions" })}
                <Link to="/productions" />
              </MenuItem>
              <MenuItem
                icon={<FaProductHunt />}
                active={pathname.includes("components")}
              >
                {intl.formatMessage({ id: "components" })}
                <Link to="/components" />
              </MenuItem>
              {settings?.useInventoryModule && (
                <MenuItem
                  icon={<FaProductHunt />}
                  active={pathname.includes("inventory-transactions")}
                >
                  {intl.formatMessage({ id: "inventoryTransactions" })}
                  <Link to="/inventory-transactions" />
                </MenuItem>
              )}
              <MenuItem
                icon={<FaBoxes />}
                active={pathname.includes("finished-goods-inventory-status")}
              >
                {intl.formatMessage({ id: "finishedGoodsInventory" })}
                <Link to="/finished-goods-inventory-status" />
              </MenuItem>
              {settings?.usePrintedFilm && (
                <MenuItem
                  icon={<FaBoxes />}
                  active={pathname.includes("printed-films-inventory")}
                >
                  {intl.formatMessage({ id: "printedFilmsInventory" })}
                  <Link to="/printed-films-inventory" />
                </MenuItem>
              )}
            </SubMenu>
            <Tooltip
              align="right"
              className="lg-tooltip-container"
              popoverZIndex={99999}
              enabled={collapsed}
              darkMode={true}
              trigger={
                <MenuItem
                  icon={<FaImages />}
                  active={pathname.includes("graphics")}
                >
                  {intl.formatMessage({ id: "graphics" })}
                  <Link to="/graphics" />
                </MenuItem>
              }
            >
              {intl.formatMessage({ id: "graphics" })}
            </Tooltip>
            <Tooltip
              align="right"
              className="lg-tooltip-container"
              popoverZIndex={99999}
              enabled={collapsed}
              darkMode={true}
              trigger={
                <MenuItem
                  icon={<FaRecycle />}
                  active={pathname.includes("defect")}
                >
                  {intl.formatMessage({ id: "defect" })}
                  <Link to="/defect" />
                </MenuItem>
              }
            >
              {intl.formatMessage({ id: "defect" })}
            </Tooltip>
            <Tooltip
              align="right"
              className="lg-tooltip-container"
              popoverZIndex={99999}
              enabled={collapsed}
              darkMode={true}
              trigger={
                <MenuItem
                  icon={<FaLayerGroup />}
                  active={pathname.includes("bins")}
                >
                  {intl.formatMessage({ id: "bins" })}
                  <Link to="/bins" />
                </MenuItem>
              }
            >
              {intl.formatMessage({ id: "bins" })}
            </Tooltip>
            {user?.role === "admin" && (
              <>
                <Tooltip
                  align="right"
                  className="lg-tooltip-container"
                  popoverZIndex={99999}
                  enabled={collapsed}
                  darkMode={true}
                  trigger={
                    <MenuItem
                      icon={<FaUsersCog />}
                      active={pathname && pathname.includes("users")}
                    >
                      {intl.formatMessage({ id: "users" })}
                      <Link to="/users" />
                    </MenuItem>
                  }
                >
                  {intl.formatMessage({ id: "users" })}
                </Tooltip>
                <Tooltip
                  align="right"
                  className="lg-tooltip-container"
                  popoverZIndex={99999}
                  enabled={collapsed}
                  darkMode={true}
                  trigger={
                    <MenuItem
                      icon={<FaClipboardList />}
                      active={pathname.includes("logs")}
                    >
                      {intl.formatMessage({ id: "logs" })}
                      <Link to="/logs" />
                    </MenuItem>
                  }
                >
                  {intl.formatMessage({ id: "logs" })}
                </Tooltip>
                <Tooltip
                  align="right"
                  className="lg-tooltip-container"
                  popoverZIndex={99999}
                  enabled={collapsed}
                  darkMode={true}
                  trigger={
                    <MenuItem
                      icon={<FaCogs />}
                      active={pathname.includes("settings")}
                    >
                      {intl.formatMessage({ id: "settings" })}
                      <Link to="/settings" />
                    </MenuItem>
                  }
                >
                  {intl.formatMessage({ id: "settings" })}
                </Tooltip>
                <Tooltip
                  align="right"
                  className="lg-tooltip-container"
                  popoverZIndex={99999}
                  enabled={collapsed}
                  darkMode={true}
                  trigger={
                    <MenuItem
                      icon={<FaGem />}
                      active={pathname.includes("configvars")}
                    >
                      {intl.formatMessage({ id: "configvars" })}
                      <Link to="/configvars" />
                    </MenuItem>
                  }
                >
                  {intl.formatMessage({ id: "configvars" })}
                </Tooltip>
              </>
            )}
            {user?.username.includes("su") && (
              <MenuItem icon={<FaHdd />} active={pathname.includes("sessions")}>
                {intl.formatMessage({ id: "sessions" })}
                <Link to="/sessions" />
              </MenuItem>
            )}
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{ textAlign: "center" }}>
          <div className="sidebar-btn-wrapper">
            <span
              className="sidebar-btn swing-icon"
              onClick={() => handleCollapsedChange(collapsed)}
              role="button"
            >
              {collapsed ? <FaArrowRight /> : <FaArrowLeft />}
            </span>
          </div>
        </SidebarFooter>
      </ProSidebar>
    );
  }
);

export default Aside;
